<template>
  <div id="politica">
    <h1>Política de Privacidade e de Segurança</h1>
    <p>
      A Política de Privacidade e de Segurança da Empório das Marcas
      foi criada para para demonstrar o compromisso da Empório com
      a segurança e a privacidade das informações informadas/coletadas
      pelos nossos usuários nas nossas plataformas. Essa política estabelece
      as condições gerais de coleta, armazenamento, uso, tratamento e proteção
      de dados nas nossas plataformas, em conformidade com a Lei 12.965/2014
      (Marco Civil da Internet) e o Decreto nº 8.771/2016. Ao utilizar nossas
      plataformas (sites, aplicativo, ou aplicações de internet) você concorda
      expressamente e integralmente com esta Política de Privacidade e de Segurança.
      <br />É muito importante que seja realizada a leitura atenta desta política antes de prosseguir.
    </p>
    <p>
      <br /><b>1. Finalidade da Coleta de Informações</b>
      <br />Sabendo mais de você podemos garantir uma série de vantagens para você aproveitar o melhor da plataforma, além de possibilitar uma usabilidade personalizada baseada nas suas informações.
      <br />A coleta de dados das nossas plataformas é realizada com os seguintes objetivos:
      <br />a) Adequar a plataforma e o conteúdo baseado nas suas preferências, a fim de proporcionar uma experiência e atendimento único e personalizado;
      <br />b) Autenticar acessos;
      <br />c) Garantir a eficácia na prestação do nosso serviço a você;
      <br />d) Buscar segurança adequada aos serviços oferecidos e meios de identificação no caso de usos impróprios ou ilícito;
      <br />e) Resolver problemas e formular notificações relacionados ao seu uso das nossas plataformas.
      <br />f) Melhorias das nossas plataformas.
      <br />g) Divulgar ações promocionais nas nossas plataformas ou lojas.
      <br />h) Determinar a eficácia das nossas promoções e publicidades.
      <br />i) Divulgar ações promocionais de empresas parceiras que adotem medidas de proteção à privacidade e segurança similares àquelas estabelecidas nesta Política.
      <br />j) Apurar informações estatísticas.
      <br />
      <br /><b>2. Informações Coletadas</b>
      <br />Para atender às finalidades acima, nossas plataformas podem coletar dados a partir de informações:
      <br />a) Que você mesmo nos fornece, espontaneamente, mediante a criação de contas ou a inserção de referências a seu respeito nas nossas plataformas;
      <br />b) Sobre a forma como você navega em nossas plataformas, bem como sobre registros de acessos e de interações em geral nas nossas plataformas, incluindo informações:
      <br />i. Sobre o dispositivo e os sistemas/navegadores que você utiliza para acessar nossas plataformas, incluindo o endereço de protocolo de Internet (número IP).
      <br />ii. Passíveis de identificar todo e qualquer uso e interação no contexto das nossas plataformas, incluindo os seus hábitos de compra e preferências em geral;
      <br />iii. Referentes ao momento de cada ação sua em nossas plataformas (data e hora).
      <br />iv. Referentes à sua localização.
      <br />c) Fornecidas por sites, ferramentas ou plataformas externas disponibilizadas por terceiros. Cookies, tags e identificadores anônimos contidos em e-mails enviados a você também podem ser usados para monitorar suas interações, como, por exemplo, quando recebeu, abriu ou clicou em um link em um e-mail enviado por nós.
      <br />
      <br /><b>3. Como armazenamos, tratamos e protegemos essas informações?</b>
      <br />Nossas plataformas promove a guarda dos seus respectivos registros de acesso, em ambiente controlado e de segurança, pelo prazo de 06 (seis) meses, nos termos do art. 15 da lei 12.965/14. Após esse período, os registros de acessos às nossas plataformas são automaticamente excluídos, nos termos do art.13, §2º, II, do Decreto 8.771/2016.
      <br />Além disso, nossas plataformas também mantêm, igualmente em ambiente controlado e de segurança, alguns dados pessoais de usuários visando a atender finalidades específicas, conforme exposto nos artigos (1) e (2), nos termos do art. 10 da lei 12.965/14. Tais dados são automaticamente excluídos tão logo atingida a finalidade de seu uso, conforme (1) acima, e nos descritos termos do art. 13, 2º, I, do Decreto 8.771/2016.
      <br />Os dados coletados através das nossas plataformas são armazenados, tratados e protegidos mediante as melhores práticas de hospedagem, processamento, backup e criptografia, de acordo com a sua criticidade. Os dados pessoais coletados são mantidos em sigilo, com controle estrito de acesso, mediante mecanismos de autenticação.
      <br />Mesmo assim, o usuário deve ter ciência que nenhum sistema de segurança na Internet é garantido contra invasões indesejadas, sendo que o compromisso da Empório se limita à adoção de medidas de proteção recomendáveis de acordo com o atual estado da técnica, e em conformidade com a Lei 112.965/2014 (Marco Civil da Internet) e o Decreto nº 8.771/2016 (Regulamentação do Marco Civil da Internet). Logo, a Empório das Marcas não se responsabiliza por invasões ou qualquer infração às normas e decretos estabelecidos nesta política.
      <br />Devido às características estruturais da internet, é possível que os dados coletados sejam armazenados ou tratadas no exterior, não sendo de responsabilidade da Empório.
      <br />
      <br /><b>4. Dados relativos a operações financeiras</b>
      <br />Os dados referentes a transações financeira são objeto de mecanismos adicionais de segurança visando a sua autenticidade, confidencialidade e integridade. Recursos técnicos como certificação digital e criptografia são utilizados neste sentido. As informações que trafegam através do nosso processo de compra e venda online são protegidas por HTTPS (certificação SSL). O processo de identificação (Login e senha) é obrigatório em todo processo de compra. Os números do cartão de pagamento informados pelo cliente são utilizados exclusivamente para fins de autorização das transações de compra e/ou pagamento, sendo criptografados e mascarados de acordo com as melhores práticas de mercado.
      <br /><br /><b>5. Compartilhamento de dados</b>
      <br />Nossas plataformas somente compartilham dados:
      <br />a) com outras empresas integrantes do mesmo grupo econômico, controlados pela Empório das Marcas ou controladora da mesma;
      <br />b) nas hipóteses previstas nesta cláusula; ou
      <br />c) mediante autorização prévia do usuário.
      <br />Nossas aplicações poderão se valer de prestadores de serviço terceirizados para o armazenamento e o tratamento de dados. Nesses casos, estes prestadores de serviços especializados sempre se submeterão à adoção de contrato que assegure o sigilo e confidencialidade.
      <br />Nossas plataformas também poderão compartilhar dados com terceiros mediantes ordem judicial ou solicitação do Ministério Público, de autoridade policial ou administrativa, nos termos do art. 15, parágrafo 3º, da lei 12.965/14, e dos arts. 11 e 15, do Decreto 8.771/2016, ou, ainda, se assim for necessário para cumprir qualquer outra lei ou regulamentação governamental.
      <br />
      <br /><b>6. Gerenciamento, correção e exclusão de dados</b>
      <br />A Empório não se responsabiliza pela correção, veracidade, autenticidade, completude e atualização dos dados prestados por usuários de suas plataformas, nem mesmo pelo eventual uso indevido de informações publicadas por usuários ou por fraudes decorrentes da violação de senha.
      <br />É da exclusiva responsabilidade do usuário prestar unicamente informações corretas, verdadeiras, autênticas, completas e atualizadas, bem como zelar pelo sigilo de sua senha, quando aplicável, não divulgando-a a terceiros.
      <br />A Empório das Marcas também mantém canais de atendimento aos usuários e para denúncia de dados falsos/equivocados publicados nas nossas plataformas através do endereço eletrônico emporiomarcasparnamirim@gmail.com ou pelo telefone (84) 9 9891-8064. Nesses canais também podem ser encaminhadas quaisquer outras denúncias.
      <br />
      <br /><b>7. Edição e exclusão de conteúdos</b>
      <br />Nossas plataformas se reservam o direito de editar ou mesmo excluir quaisquer conteúdos publicados por usuários que não estejam em conformidade com a lei, com a sua Política de Privacidade e Segurança ou Termos de Uso.
      <br />Essa condição, porém, não configura obrigação da Empório das Marcas, sendo que, por força do art. 15, parágrafo 3º da Lei 12.965/14, art. 11 e 15, do Decreto 8.771/2016, via de regra solicitações de edição ou remoção devem se dar sob a forma de ordem judicial, solicitação do Ministério Público, de autoridade policial ou administrativa.
      <br />
      <br /><b>8. Emails não solicitados</b>
      <br />Nossas plataformas buscam evitar o envio de e-mails não solicitados, restringindo as comunicações com você a assuntos que sejam relevante ou de seu específico interesse.
      <br />Quando você faz uma compra on-line, confirmamos o seu pedido por e-mail e também podemos contatá-lo através dos meios de comunicação informados no nosso cadastro, a fim de confirmar a operação realizada em nossas plataformas.
      <br />Caso você prefira não receber mais nossas informações promocionais, tais como e-mails sobre ofertas especiais e eventos de vendas, você pode solicitar seu descadastramento através do link de cancelamento de inscrição contido no e-mail, ou, na sua página de cadastro, desmarcar a opção que autoriza o envio de e-mails promocionais.
      <br />Após o descadastramento, você ainda poderá receber comunicações de segurança ou relativas às compras realizadas.
      <br />Você deve ter ciência de que diversos fraudadores tentam se valer de marcas famosas para obter informações pessoais como senhas e dados de cartões de crédito. A EMPÓRIO DAS MARCAS NUNCA IRÁ SOLICITAR SUA SENHA, E-MAIL, DADOS DE CARTÃO DE CRÉDITO/DÉBITO ATRAVÉS DE E-MAIL OU CONTATO TELEFÔNICO. Colabore conosco enviando reclamações para emporiomarcasparnamirim@gmail.com caso você receba qualquer e-mail não-solicitado ou potencialmente invasivo em nome da Empório das Marcas.
      <br />
      <br /><b>9. Conexões (links) a outros sites</b>
      <br />Nossas plataformas podem conter links ou frames de outros sites. Esses links ou frames buscam proporcionar benefícios adicionais aos conteúdos e serviços oferecidos aos nossos usuários.
      <br />Esclarecemos que a inclusão desses links nas nossas plataformas não significa que a Empório das Marcas tenha conhecimento, concorde ou seja responsável pelos referidos links e frames, ou por seus respectivos conteúdos. É importante ressaltar que nosso objetivo é o de somente disponibilizar links ou frames de empresas idôneas e confiáveis. Porém, a Empório das Marcas não é responsável pelas informações, produtos ou serviços obtidos pelos usuários nos referidos sites, nem tampouco pelas práticas comerciais e políticas de privacidade adotadas por essas empresas, podendo ser responsabilizada por eventuais perdas e danos ou lucros cessantes sofridos em razão da utilização desses recursos.
      <br />
      <br /><b>10. Prazo e Alterações</b>
      <br />O funcionamento das nossas plataformas se dá por prazo indeterminado.
      <br />As nossas plataformas, no todo ou em cada uma das nossas seções, podem ser encerrados, suspensos ou interrompidos unilateralmente pela Empório das Marcas, a qualquer momento e sem necessidade de prévio aviso.
      <br />A presente Política de Privacidade e Segurança também pode ser alterada a qualquer tempo. As alterações desta Política de Privacidade e Segurança serão informadas com destaque nas nossas plataformas.
      <br />
      <br /><b>11. Lei aplicável e foro competente</b>
      <br />Na interpretação da presente Política de Privacidade e Segurança, aplica-se a legislação brasileira.
      <br />Quaisquer litígios relacionados a esta Política de Privacidade e Segurança serão da competência exclusiva do 7º Ofício de Notas de Natal, RN, Brasil.
      <br />Todos os conteúdos e informações contidos nas nossas plataformas da Empório das marcas têm proteção assegurada pelas leis que regulamentam direitos e autorais, marcas e outros sinais distintivos.
      <br />Não é permitida a reprodução total ou parcial dos conteúdos e informações contidas nas plataformas da Empório das Marcas.
      <br /><br />Copyright © 2020 Empório das Marcas.
      <br />Endereço: Av. Brigadeiro Eduardo Gomes, nº 250, Emaús, Parnamirim/RN.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style>

#politica{
    width: 70%;
    margin: 0 auto;
}
</style>
